import React from 'react';
import { Button } from 'react-bootstrap';

// https://fontawesome.com/docs/web/use-with/reactimport { Container, Row, Col, Spinner, Button } from 'react-bootstrap';/
// https://fontawesome.com/v6/icons
// https://github.com/FortAwesome/Font-Awesome/blob/master/UPGRADING.md#50x-to-510
// https://fontawesome.com/v4/icons/
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleRight, faAngleDoubleLeft } from '@fortawesome/free-solid-svg-icons'

class Expansion extends React.Component {
    constructor(props) {
        super(props);

        this.action = this.action.bind(this);
    }

    action = () => {
        this.props.toggle(this.props.index);
    }
        
    render() {


        if (this.props.states[this.props.index]) {
            return(<div className="pt-1 pb-1">{this.props.title} <Button  variant="secondary" onClick={this.action} size="sm"><FontAwesomeIcon icon={faAngleDoubleLeft}/></Button></div>)
        } else {
            return(<div className="pt-1 pb-1">{this.props.title} <Button  variant="primary" onClick={this.action} size="sm"><FontAwesomeIcon icon={faAngleDoubleRight}/></Button></div>)
        }
    }
}

export default Expansion;