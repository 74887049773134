// pages/Home.js
import './App.css';



import React, {useEffect, useState} from "react";

//import Logo from './epdlogo_lg.jpg'

import { Spinner, Container, Row, Col, Nav, Button, Image} from 'react-bootstrap';

import { ApiPost, ApiGet } from './Api';

import { NavLink, withRouter } from 'react-router-dom';

import Logo from './epdlogo_lg.jpg'

import {UptimeMergeToData, BackupMergeToData, HostingMergeToData, EpdSmartApiLoad, EpdFullApiLoad} from './Library';
import { ComponentPropsToStylePropsMap } from '@aws-amplify/ui-react';

import { createBrowserHistory } from 'history';
import { wait } from '@testing-library/react';
import { sortByField } from '@aws-amplify/core';


import { useCookies } from 'react-cookie'

import axios from 'axios';

import { baseURL } from './config';


const browserHistory = createBrowserHistory();



const textCenter = {
  textAlign: "center"
};

const textRight = {
  textAlign: "right"
};

const rowStyle = {
  // height: '100vw',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
 // marginBottom: '1rem'
  padding: '1rem'
};


/*
const textCenter = {
  textAlign: "center"
};


const rowStyle = {
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  padding: '1rem'
};
*/

//<Row style={{...rowStyle, ...textCenter}}>
/*


*/




const body = {
    grant_type: 'authorization_code',
    code:       '',
    redirect_url: ''
}


const parseQueryString = (path = window.location.search) =>
    path.slice(1).split('&').reduce((car, cur) => { 
    const [key, value]= cur.split('=') 
    return { ...car, [key]: value}
}, {})

const Sonos = (props) => {

    const [access_token, setAccess_token] = useState('');
    const [refresh_token, setRefresh_token] = useState('');
    const [code, setCode] = useState('');
    
   // const [name, setName] =useState('');
    //const [pwd, setPwd] = useState('');
    const [cookies, setCookie] = useCookies(['use']);

 

    console.log('base url', baseURL);
    


    useEffect(() => {

        console.log('SONOS-----------UPDATE FROM COOKIES', props);
        //const propscopy = {...props, unloading: true}
        //EpdSmartApiLoad(props, true);

        setAccess_token(cookies.access_token);
        setRefresh_token(cookies.refresh_token);
        setCode(cookies.code);

        const search = parseQueryString();
        if (search.code !== undefined) {
            setCookie('code', search.code, { path: '/'});
            setCode(search.code);
        }

    },[]
    )





    //setCookie('Name', 'name',{ path: '/'});
    //setCookie('Pwd', 'pwd',{ path: '/'});
    //setCookie('Fred', 'fed',{ path: '/'});





    // https://api.ws.sonos.com/control/api/v1/households/{householdId}/groups/createGroup

    const getHousehold = () => {

        const url = 'https://api.ws.sonos.com/control/api/'
        const method = 'v1/households'
       // const auth = cookies.access_token;
        const auth = access_token;

        console.log('getHousehold access', access_token);

        const headers = {
            Authorization: `Bearer ${auth}`,
         //   Authorization: `Bearer ${'C6zo2ndrn7nneHr7PjdXXjQ3XpzD'}`,
         //   'Content-Type': 'application/x-www-form-urlencoded',
            "Content-Type": "application/json", 
            "Access-Control-Allow-Headers": "*",
            "Access-Control-Allow-Methods": "*",
            //"Access-Control-Allow-Origin": "http://localhost:3000"
            //"Access-Control-Allow-Origin": baseURL
            "Access-Control-Allow-Origin": "*",

        }

        console.log('axios', url, method, headers);


        //axios.defaults.baseURL = 'https://dev.epd.easypeasydigital.com/';
        //axios.defaults.proxy = 'https://dev.epd.easypeasydigital.com/';
        console.log('axios settings', axios.defaults)

        axios.get(`${url}${method}`, { headers })
        .then(res => { 
            console.log('axios get', res);
        })
        .catch(err => {
            console.log('axios get error', err);
        });       

    }

    const createToken = () => {

        //{access_token: '7FG6eiXLGcLNO4633CEtwTJK0Txh', token_type: 'Bearer', expires_in: 86399, refresh_token: 'sdkmUyK9cGIx8Ywd4DFpJjtpkTA3StqI', scope: 'playback-control-all'}
   
        //const redirect_uri = encodeURI('http://localhost:3000/sonos');

        const url = 'authz/createToken'

        const parameters = {
            grant_type    : 'authorization_code',
            //code          : cookies.code,
            code          : code,
            redirect_uri  : `${baseURL}sonos`
        }

        const params = new URLSearchParams(parameters).toString();

        //console.log('uri', redirect_uri);

        //     ApiGet(`authz/token/${props.code}/${redirect_url}`)
        
        //ApiGet(`authz/createToken?grant_type=${'authorization_code'}&code=${cookies.code}&redirect_uri=${redirect_uri}`)
       
        ApiGet(`${url}?${params}`)
        .then(res => { 
            console.log('token', res);
            setCookie('access_token', res.access_token,{ path: '/'});
            setCookie('refresh_token', res.refresh_token,{ path: '/'});
            setAccess_token(res.access_token);
            setRefresh_token(res.refresh_token);    
        })
        .catch(err => {
            console.log('authz error', err);
        });       
    }

    const refreshToken = () => {

        //{access_token: '7FG6eiXLGcLNO4633CEtwTJK0Txh', token_type: 'Bearer', expires_in: 86399, refresh_token: 'sdkmUyK9cGIx8Ywd4DFpJjtpkTA3StqI', scope: 'playback-control-all'}

        //const redirect_uri = encodeURI('http://localhost:3000/sonos');

        //console.log('uri', redirect_uri);

        const url = 'authz/refreshToken'

        const parameters = {
            //refresh_token : cookies.refresh_token,
            refresh_token : refresh_token,
            grant_type    : 'refresh_token'
        }

        const params = new URLSearchParams(parameters).toString();

        //     ApiGet(`authz/token/${props.code}/${redirect_url}`)
       
        //ApiGet(`authz/refreshToken?refresh_token=${cookies.refresh_token}&grant_type=${'refresh_token'}`)
        
        ApiGet(`${url}?${params}`)
        .then(res => { 
            console.log('token', res);
            setCookie('access_token', res.access_token,{ path: '/'});
            setCookie('refresh_token', res.refresh_token,{ path: '/'});
            setAccess_token(res.access_token);
            setRefresh_token(res.refresh_token);     
        })
        .catch(err => {
            console.log('authz error', err);
        });       
    }   


    const authz = () => {

        ApiGet("authz/getAuthKey")

        //ApiGetArray(['google', 'domain','backup','uptime','hosting'])
        .then(res => { 
            console.log('authz', res);
            
            const url = 'https://api.sonos.com/login/v3/oauth'

            const parameters = {
                client_id     : res.Key,
                redirect_uri  : `${baseURL}sonos`,
                response_type : 'code',
                scope         : 'playback-control-all',
                state         : '1234',
            }

            const params = new URLSearchParams(parameters).toString();

            console.log('params', params);
            
            //window.location.href= `https://api.sonos.com/login/v3/oauth?client_id=${client_id}&response_type=${response_type}&state=${state}&scope=${scope}&redirect_uri=${redirect_uri}`
      

            window.location.href= `${url}?${params}`
      
        })
        .catch(err => {
            console.log('authz error', err);
        });   
        

    }
/*
  useEffect(() => {

      console.log('HOME-----------', props);
      const propscopy = {...props, unloading: true}
      EpdSmartApiLoad(props, true);

  },[props]
  )
*/

  //const loading = props.state.loading;

  const textStyle = { 
    textAlign: "center"
  }

    return (
      <Container fluid="lg" className="content_container">
        <Row>
            <Col>

            </Col>
        </Row>
        <Row style={{...rowStyle, ...textCenter}}>
            <Col>
                {console.log('*****Cookies etc:', cookies, 'access_token:', access_token, 'refresh_token:', refresh_token, 'code:',code)}
                <h2>Code = {props.code}</h2>
                <Button onClick={authz}>Create Auth Code</Button>
                <Button onClick={createToken}>Create Token</Button>
                <Button onClick={refreshToken}>Refresh Token</Button>
                <Button onClick={getHousehold}>getHousehold</Button>
            </Col>
        </Row>

        <Row>
            
        </Row>
            
        
      </Container>
    )
  };

export default Sonos;
