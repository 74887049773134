function setConfig() {

    const dev = {

        Environment: 'development',
        BaseURL:  'http://localhost:3000/',

        Auth: {

            region: 'eu-west-2',
            userPoolId: 'eu-west-2_5upjqKet2',
            userPoolWebClientId: '3g0nd0ris80vobgrh5cck6pb02',

            API: {
                endpoints: [
                    {
                        name: "epD-API-dev",
                        endpoint: "https://0p9c4jo93h.execute-api.eu-west-2.amazonaws.com",
                    }
                ]
            }
        },
        ApiGateway: {

            apiName: 'epD-API-dev',
            apiPath: '/api/',

            apiInit: {
                headers: {
                    "Access-Control-Allow-Headers": "*",
                    "Access-Control-Allow-Methods": "*",
                    "Access-Control-Allow-Origin": "*"
                }
            }
        }
    }

    const stage = {
 
        Environment: 'staging',
        BaseURL:  'https://dev.epd.easypeasydigital.com/',

        Auth: {

            region: 'eu-west-2',
            userPoolId: 'eu-west-2_5upjqKet2',
            userPoolWebClientId: '3v9k22lo6hf9ugmnhebkcn8li7', /* CloudFormation */
            API: {
                endpoints: [
                    {
                        name: "epD-API-dev",
                        endpoint: " https://dev.epd.easypeasydigital.com"
                    }
                ]
            }
        },
        ApiGateway: {  // stage uses the dev instance of api gateway

            apiName: 'epD-API-dev',
            apiPath: '/api/', // the (dev) environment for api gateway is provided by cloudfront

            apiInit: {
                headers: {
                    "Access-Control-Allow-Headers": "*",
                    "Access-Control-Allow-Methods": "*",
                    "Access-Control-Allow-Origin": "*"
                }
            }
        }
    }



    const prod = {

        Environment: '',
        BaseURL:  'https://epd.easypeasydigital.com/',

        Auth: {

            region: 'eu-west-2',
            userPoolId: '', 
            userPoolWebClientId: '', /* CloudFormation */

            API: {
                endpoints: [
                    {
                        name: "epD-API",
                        endpoint: "https://epd.easypeasydigital.com",
                    }
                ]
            }
        },
        ApiGateway: {

            apiName: 'epD-API',
            apiPath: '/api/', // the (prod) environment for api gateway is provided by cloudfront

            apiInit: {
                headers: {
                    "Access-Control-Allow-Headers": "*",
                    "Access-Control-Allow-Methods": "*",
                    "Access-Control-Allow-Origin": "*"
                }
            }
        }
    };



    switch (process.env.REACT_APP_STAGE.trim()) {
        case 'prod':
            return (prod);

        case 'stage':
            return (stage);

        case 'dev':
        default:
            return (dev);
    }
}


//const config = (process.env.REACT_APP_STAGE === 'prod') ? prod : dev;


const config = setConfig();

const awsconfig = config.Auth;
const environment = config.Environment + ' 1v07';
const apiGateway = config.ApiGateway;
const baseURL = config.BaseURL




//consconfit awsconfig = dev.Auth;
//const Environment = process.env.REACg.T_APP_STAGE;

export { awsconfig, environment, apiGateway, baseURL };
