
import React from 'react';
import {ApiCallArray, ApiGetArray, ApiGet, ApiGetNoWait, ApiHeaders} from './Api';
import {HostingFirstStageContent} from './Epdhosting';
import {BackupFirstStageContent} from './Epdbackupstatus';
import {UptimeFirstStageContent} from './Epduptime';
import { CookieStorage } from '@aws-amplify/auth';
import { apiGateway } from './config';


const contentFsm = { UNDEFINED: 0, REQUEST_SIGN_OUT: 1, SIGNED_OUT: 10, REQUEST_SIGN_IN: 11, SIGNED_IN: 100, REQUEST_CODE: 200 };

const refreshTimeoutMins = { EXPIRED: 10, REFRESHABLE: 2 };


const RAGuptime = (item) => {
    const RAG = {Blue: 100.00, Green: 99.00, Amber: 98.00, Red: 0.0};

    let                           rag = 'Red';  // default bad

    if      (item >= RAG.Blue)  { rag = 'Blue';  }
    else if (item >= RAG.Green) { rag = 'Green'; } 
    else if (item >= RAG.Amber) { rag = 'Amber'; }

    return rag;
}


const RAGhostingDisk = (item) => {
    const RAG = {Blue: 0, Green: 500, Amber: 1000, Red: 3000, Black: 10000};

    let                           rag = 'Blue'; 

    if      (item >= RAG.Black) { rag = 'Black'; }
    else if (item >= RAG.Red)   { rag = 'Red';   }
    else if (item >= RAG.Amber) { rag = 'Amber'; } 
    else if (item >= RAG.Green) { rag = 'Green'; }

    return rag;
}


const RAGhostingPercent = (item) => {
    const RAG = {Blue: 0, Green: 50, Amber: 75.00, Red: 90, Black: 95};

    let                           rag = 'Blue';  // default bad

    if      (item >= RAG.Black) { rag = 'Black'; }
    else if (item >= RAG.Red)   { rag = 'Red';   }
    else if (item >= RAG.Amber) { rag = 'Amber'; } 
    else if (item >= RAG.Green) { rag = 'Green'; }

    return rag;
}



function pad(value) {
    if (value < 10) {
        return '0' + value;
    }
    else {
        return value;
    }
}


const penceToPounds = (value) => {
    if (value == 0) { return "" }
    
    //else

    //const numberString = ((parseFloat(value)/100).toFixed(2)).toLocaleString("en-UK");

    //const numberString = ((parseFloat(value)/100).toFixed(2)).toLocaleString("en-UK");

    const numberString = parseInt(value/100).toLocaleString("en-UK")+"."+pad(parseInt(value%100));

    return numberString;
}

const byteToMegabyte = (byteSize) => {

    const sizeUnits = 1000000; // MB

 //   const newUnitSize = parseInt(byteSize / sizeUnits);
 //   const newUnitSize = (byteSize / sizeUnits) - parseInt(byteSize / sizeUnits);

    // round to nearest
    const floating = byteSize / sizeUnits;
    var integer = parseInt(floating);
    if ((floating - integer) >= 0.5) { integer++ }

    return (integer);

}


const secondstotime = (seconds) => { 
    if (seconds === '') return (''); // exit func if empty   

    const Hours     = parseInt(seconds/3600);
    const Minutes   = parseInt((seconds - Hours*3600)/60);
    const Seconds   = parseInt((seconds - Hours*3600 - Minutes*60));

    const formattedTime = pad(Hours) + ':' + pad(Minutes) + ':' + pad(Seconds);

    return (formattedTime);
}

const timedateConvert = (timestamp) => { 

    if (timestamp === '') return (''); // exit func if empty

    const d = new Date(timestamp);

    const formattedTimeDate = pad(d.getHours()) + ':' + pad(d.getMinutes()) + ' ' + pad(d.getDate()) + '/' + pad(d.getMonth()+1) + '/' + d.getFullYear();

    return (formattedTimeDate);
}

const daysOld = (timestamp) => {

    if (timestamp === '') return (''); // exit func if empty

    const msToDays = 86400000;

    const now = new Date();
    const oldDate = new Date(timestamp);

    const age = parseInt((Number(now- oldDate) / msToDays), 10);

    return (age);
}

const minsOld = (timestamp) => {

    if (timestamp === '') return (''); // exit func if empty

    const msToMins = 60000;

    const now = new Date();
    const oldDate = new Date(timestamp);

    const age = parseInt((Number(now- oldDate) / msToMins), 10);

    return (age);
}


const SortAndIndex = (input) => {
    const output = input;

    output.sort((a, b) => {
        if (a.domain > b.domain) return  1;
        if (a.domain < b.domain) return -1;
        return 0;
    });
    
    
    let index = 1;
    output.forEach((row) => { row.index = index++ });
    
    return output;
}

const EpdSmartApiLoad = (state, setState, action=null)  => {
    //console.log('EpdSmartApiLoad', state);
 /*   if ((!state.loading) && (state.data.length===0) && (state.authy === contentFsm.SIGNED_IN)) {
        console.log('EpdSmartApiLoad LOAD');
        setState({loading: true});
        EpdFullApiLoad(setState);
    
    }*/

    if (!state.loading) {


        switch (action) {
            
            case "unload":
                            console.log('EpdSmartApiLoad UNLOAD');
                            setState({user: [], data: [], lastUpdate: null, loading: false, err: ""});

                            break;
                        
            case "force":
                            if (state.authy === contentFsm.SIGNED_IN) {
                                console.log('EpdSmartApiLoad FORCE LOAD');
                                setState({loading: true});
                                EpdFullApiLoad(setState);
                            }
                            break;

            case "minimum":
                            if (state.authy === contentFsm.SIGNED_IN) {
                                console.log('EpdSmartApiLoad MINIMUM TIMED LOAD');
                                if ((state.lastUpdate !== null)) {
                                    if (minsOld(state.lastUpdate) >= refreshTimeoutMins.REFRESHABLE)
                                    {
                                        setState({loading: true});
                                        EpdFullApiLoad(setState);
                                    }
                                }
                                else {
                                    setState({loading: true});
                                    EpdFullApiLoad(setState); 
                                }
                            }
                            break;
            
            case "maximum":
            default:
                            if (state.authy === contentFsm.SIGNED_IN) {
                                console.log('EpdSmartApiLoad MAXIMUM TIMED LOAD');
                                if ((state.lastUpdate !== null)) {
                                    if (minsOld(state.lastUpdate) >= refreshTimeoutMins.EXPIRED)
                                    {
                                        setState({loading: true});
                                        EpdFullApiLoad(setState);
                                    }
                                }
                                else {
                                    setState({loading: true});
                                    EpdFullApiLoad(setState); 
                                }
                            }
                            break;
            }
    }
/*
    if ((state.authy === contentFsm.SIGNED_IN) && (!state.loading) && ((state.data.length === 0) || (action === "force"))) {
            console.log('EpdSmartApiLoad LOAD');
            setState({loading: true});
            EpdFullApiLoad(setState);
    }
    else {
        if ((!state.loading) && (action === "unload")) {
            console.log('EpdSmartApiLoad UNLOAD');
            setState({user: [], data: [], lastUpdate: null, error: ""});
        }
    }
*/
}

/*
const EpdSmartApiLoad = (props, unload=false)  => {
    console.log('EpdSmartApiLoad', props);
    if ((!props.state.loading) && (props.state.data.length ===0)) {
        console.log('EpdSmartApiLoad LOAD');
        props.setState({loading: true});
        EpdFullApiLoad(props.setState);
    
    } else {
        if ((!props.state.loading) && (props.state.data.length !== 0) && (unload)) {
            console.log('EpdSmartApiLoad UNLOAD');
            props.setState({user: [], data: []});
        }
    }
}
*/


/*
const EpdFullApiLoad = (setState) => {
    ApiGet('domain')
    .then(res => {
        const domains = res.Items;
            ApiGet('google')                                                    // gets the user information 
             .then(res => { 
                const userInfo= res;
                //this.setState({ user: res });
                ApiGet('backup')                  
                    .then(res => {
                    const withBackups = BackupMergeToData(res, domains);
                    ApiGet('uptime') 
                        .then(res => {
                            const withUptime = UptimeMergeToData(res.monitors, withBackups); // merges the monitors into the sites array
                            ApiGet('hosting') 
                            .then(res => {
                                const withHosting = HostingMergeToData(res.listaccts.data.acct, res.showbw.data.acct, withUptime);
                                setState({ loading: false, user: userInfo, data: withHosting });
                            })
                            .catch(err => console.log('Api Summary hosting', err));
                        })
                        .catch(err => console.log('Api Summary uptime', err));       
                    })
                    .catch(err => console.log('Api Summary backup', err));
                 })
                .catch(err => console.log('Api Summary google token', err));
            })
            .catch(err => console.log('Api Summary domain', err))
}
*/

const EpdFullApiLoad = async (setState) => {


    
    let allObjects = [];

    const callListDouble = [
        {resource: 'google'}, 
        {resource: 'domain'}, 
        {resource: 'backup'}, 
        {resource: 'uptime'}, 
        {resource: 'hosting', method: 'POST', body: {domain: 'www.epdhosting.co.uk', identity: 'listaccts', method: 'listaccts', user: 'epdhosti', query: 'api.version=2'}}, 
        {resource: 'hosting', method: 'POST', body: {domain: 'www.epdhosting.co.uk', identity: 'showbw', method: 'showbw', user: 'epdhosti', query: 'api.version=2'}}
     ];


    const callList = [
        {resource: 'google'}, 
        {resource: 'domain'}, 
        {resource: 'backup'}, 
        {resource: 'uptime'}, 
        {resource: 'hosting', method: 'POST', body: [ {domain: 'www.epdhosting.co.uk', identity: 'listaccts', method: 'listaccts', user: 'epdhosti', query: 'api.version=2'}, 
                                                      {domain: 'www.epdhosting.co.uk', identity: 'showbw', method: 'showbw', user: 'epdhosti', query: 'api.version=2'}]}
    ];

    ApiCallArray(callList)

    //ApiGetArray(['google', 'domain','backup','uptime','hosting'])
    .then(res => { 


        const userInfo = res[0]; // special case for 0th element
        allObjects = mergeFetchedData(allObjects, fetchData(res[1].Items,               "information", "domain"        ));
        allObjects = mergeFetchedData(allObjects, fetchData(res[2],                     "backup",      "Prefix"        ));
        allObjects = mergeFetchedData(allObjects, fetchData(res[3].monitors,            "monitor",     "friendly_name" ));
        allObjects = mergeFetchedData(allObjects, fetchData(res[4].listaccts.data.acct, "hosting",     "domain"        ));
        allObjects = mergeFetchedData(allObjects, fetchData(res[4].showbw.data.acct,    "bandwidth",   "maindomain"    ));

        console.log('EpdFullApiLoad', userInfo, allObjects, res[1]);

        allObjects = SortAndIndex(allObjects)
        //console.log('fffffffffffffffffffffff',  allObjects);

        setState({ loading: false, user: userInfo, data: allObjects, lastUpdate: Date.now(), err: "" });
    })
    .catch(err => {
        console.log('EpdFullApiLoad', err);
        setState({ loading: false, user: [], data: [], lastUpdate: null, err: err });
    });



    //Promise.all([ApiGetNoWait('domain'), ApiGetNoWait('domain'), ApiGetNoWait('domain'), ApiGetNoWait('backup')])
    //.then(qwe => console.log('DONE', qwe))
    //.catch(err => console.log('EpdFullApiLoad', err));
    //allresults = [await ]null
/*
    ApiGet('domain')
    .then(res => {
        const domains = res.Items;
            ApiGet('google')                                                    // gets the user information 
             .then(res => { 
                const userInfo= res;
                //this.setState({ user: res });
                ApiGet('backup')                  
                    .then(res => {
                    const backup = fetchData(res, "backup", "Prefix");
                    ApiGet('uptime') 
                        .then(res => {
                            const monitor = fetchData(res.monitors, "monitor", "friendly_name");
                            ApiGet('hosting') 
                            .then(res => {
                                //const withHosting = HostingMergeToData(res.listaccts.data.acct, res.showbw.data.acct, withUptime);
                                
                                const hosting = fetchData(res.listaccts.data.acct, "hosting", "domain");
                                const bandwidth = fetchData(res.showbw.data.acct, "bandwidth", "maindomain");

                                //const everything = [...domains, ...backup, ...monitor, ...hosting, ...bandwidth]
                                //const fullDomainList = everything.map(domain => {return domain.domain});
                                //const deDupedDomainList = new Set(fullDomainList);
                                //const domainsObjects =[];
                               // deDupedDomainList.forEach((item) => domainsObjects.push({domain: item}));
                                //const sortedDomains = SortAndIndex(domainsObjects);

                                //let  finalObjects = sortedDomains;

                               // finalObjects = mergeData(finalObjects, hosting);
                                //finalObjects = mergeData(finalObjects, backup);
                                //finalObjects = mergeData(finalObjects, monitor);
                               // finalObjects = mergeData(finalObjects, hosting);
                                //finalObjects = mergeData(finalObjects, bandwidth);

                                //console.log('ddddddddddddddd', mylist, mySet, mydomains, sorted, final);

                                //console.log('eeeeeeeeeeeeeeeeeeeeeee',  finalObjects);



                                let finalObjects = domains;
                                finalObjects = mergeFetchedData(finalObjects, backup);
                                finalObjects = mergeFetchedData(finalObjects, monitor);
                                finalObjects = mergeFetchedData(finalObjects, hosting);
                                finalObjects = mergeFetchedData(finalObjects, bandwidth);
                                

                                finalObjects = SortAndIndex(finalObjects)
                                console.log('fffffffffffffffffffffff',  finalObjects);

                                setState({ loading: false, user: userInfo, data: finalObjects });
                            })
                            .catch(err => console.log('Api Summary hosting', err));
                        })
                        .catch(err => console.log('Api Summary uptime', err));       
                    })
                    .catch(err => console.log('Api Summary backup', err));
                 })
                .catch(err => console.log('Api Summary google token', err));
            })
            .catch(err => console.log('Api Summary domain', err))

*/
}


/*
const xxxEpdFullApiLoad = (setState) => {
    ApiGet('domain')
    .then(res => {
        const domains = res.Items;
            ApiGet('google')                                                    // gets the user information 
             .then(res => { 
                const userInfo= res;
                //this.setState({ user: res });
                ApiGet('backup')                  
                    .then(res => {
                    const backup = fetchData(res, "backup", "Prefix");
                    ApiGet('uptime') 
                        .then(res => {
                            const monitor = fetchData(res.monitors, "monitor", "friendly_name");
                            ApiGet('hosting') 
                            .then(res => {
                                //const withHosting = HostingMergeToData(res.listaccts.data.acct, res.showbw.data.acct, withUptime);
                                
                                const hosting = fetchData(res.listaccts.data.acct, "hosting", "domain");
                                const bandwidth = fetchData(res.showbw.data.acct, "bandwidth", "maindomain");

                                const everything = [...domains, ...backup, ...monitor, ...hosting, ...bandwidth]
                                const fullDomainList = everything.map(domain => {return domain.domain});
                                const deDupedDomainList = new Set(fullDomainList);
                                const domainsObjects =[];
                                deDupedDomainList.forEach((item) => domainsObjects.push({domain: item}));
                                const sortedDomains = SortAndIndex(domainsObjects);

                                let  finalObjects = sortedDomains;

                               // finalObjects = mergeData(finalObjects, hosting);
                                finalObjects = mergeData(finalObjects, backup);
                                finalObjects = mergeData(finalObjects, monitor);
                                finalObjects = mergeData(finalObjects, hosting);
                                finalObjects = mergeData(finalObjects, bandwidth);

                                //console.log('ddddddddddddddd', mylist, mySet, mydomains, sorted, final);

                                console.log('eeeeeeeeeeeeeeeeeeeeeee',  finalObjects);


                                setState({ loading: false, user: userInfo, data: finalObjects });
                            })
                            .catch(err => console.log('Api Summary hosting', err));
                        })
                        .catch(err => console.log('Api Summary uptime', err));       
                    })
                    .catch(err => console.log('Api Summary backup', err));
                 })
                .catch(err => console.log('Api Summary google token', err));
            })
            .catch(err => console.log('Api Summary domain', err))
}

const mergeData = (allDomains, additional) => {

    const output = [];


    allDomains.forEach((domain) => {

        const index = additional.findIndex((item) => { 
            if (item.domain === domain.domain) {return true} else {return false};
        });

        if (index >= 0) {
            output.push({...domain, ...additional[index]});
        } else {
            output.push({...domain});
        }
    })

    return  output;
}
*/

const mergeFetchedData = (allDomains, additional) => {

    const output = allDomains;


    additional.forEach((addDomain) => {

        const index = allDomains.findIndex((allDomain) => { 
            if (allDomain.domain === addDomain.domain) {return true} else {return false};
        });

        if (index >= 0) {
            output[index] = ({...output[index], ...addDomain});
        } else {
            output.push({...addDomain});
        }
    })

    return  output;
}




const fetchData = (recordset, rootKey, domainKey) => {

    const output = [];
    recordset.forEach((record) => {
        output.push({domain: record[domainKey], [rootKey]: record })
    })

    return  output;
}

/*

const EpdFullApiLoad = (setState) => {
    ApiGet('domain')
    .then(res => {
        const domains = res.Items;
            ApiGet('google')                                                    // gets the user information 
             .then(res => { 
                const userInfo= res;
                //this.setState({ user: res });
                ApiGet('backup')                  
                    .then(res => {
                    const withBackups = BackupMergeToData(res, domains);
                    ApiGet('uptime') 
                        .then(res => {
                            const withUptime = UptimeMergeToData(res.monitors, withBackups); // merges the monitors into the sites array
                            ApiGet('hosting') 
                            .then(res => {
                                const withHosting = HostingMergeToData(res.listaccts.data.acct, res.showbw.data.acct, withUptime);
                                
                                console.log('fetchData', fetchData(res.listaccts.data.acct, "bycycle", "domain"));


                                setState({ loading: false, user: userInfo, data: withHosting });
                            })
                            .catch(err => console.log('Api Summary hosting', err));
                        })
                        .catch(err => console.log('Api Summary uptime', err));       
                    })
                    .catch(err => console.log('Api Summary backup', err));
                 })
                .catch(err => console.log('Api Summary google token', err));
            })
            .catch(err => console.log('Api Summary domain', err))
}





*/


/*

const HostingMergeToData = (hosting, bandwidth, domains) => {

    const output = [];

    // add hosting to domains
    domains.forEach((domain) => {

        const index = hosting.findIndex((item) => { 
            if (item.domain === domain.domain) {return true} else {return false};
        });

        if (index >= 0) {
            output.push({...domain, hosting: {...hosting[index]}})
        } else {
            output.push({...domain})
        }
    })
    

    // add domains to hosting
    hosting.forEach((host) => {
        
        const index = output.findIndex((item) => { 
            if (item.domain === host.domain) {return true} else {return false};
        });

        if (index < 0) {
            output.push({domain: host.domain, hosting: {...host}})
        }
    })

    // add bandwidth to hosting
    bandwidth.forEach((band) => {

        const index = output.findIndex((item) => { 
            if (item.domain === band.maindomain) {return true} else {return false};
        });

        if (index >= 0) {
            output[index].bandwidth =  {...band};
        } 
    })
    


    return  SortAndIndex(output);
}

const BackupMergeToData = (backups, domains) => {

    //console.log('BMD backups:',backups, 'domains:', domains);

    //let item = 0;
    const output = [];
    domains.forEach((domain) => {

        const index = backups.findIndex((i) => { 
            if (i.Prefix === domain.domain) {return true} else {return false};
        });


        if (index >= 0) {
            output.push({...domain, backup: {...backups[index]}})
        } else {
            //output.push({...domain, backup: {status: "missing"}})
            output.push({...domain})
        }
    })




    return  SortAndIndex(output);

}



const UptimeMergeToData = (monitors, domains) => {



    const output = [];
    domains.forEach((domain) => {

        const index = monitors.findIndex((i) => { 
            if (i.friendly_name === domain.domain) {return true} else {return false};
        });


        if (index >= 0) {
            output.push({...domain, monitor: {...monitors[index]}})
        } else {
            //output.push({...domain, monitor: {status: "missing", custom_uptime_ratio: "--", custom_down_durations: "--"}})
            output.push({...domain})
        }
    })

    
    return SortAndIndex(output);
}
*/

const AnalyticsMergeToData = (analytics, domains) => {
    // takes the google list of accounts and extracts the profile to create a view which is merged into the (state) data array

    // create an array of accounts each with a view id
    const accounts = [];
    analytics.forEach((account) => {
      account.webProperties.forEach((property) => {
        property.profiles.forEach((profile) => {
          // standarise the layout of the url (remove https and www)
          var url = property.websiteUrl.replace('https:\/\/www.', 'https:\/\/').toLowerCase(); 
          url = url.replace('https:\/\/', '');
          // save new record
          //const view = 'ga'+profile.id;
          accounts.push({account: account.name, accountId: account.id, url: url, profileId: profile.id, view: 'ga:'+profile.id});
        })
      })
    })

    // create view records in 'data' with the profileIds from the google account list
    const newdata = domains; // work with a local copy of the list of 'domains' 
    accounts.forEach((account) => {
      newdata.find((d) => { 
        //if (account.url === d.domain) {d.analytics.view = 'ga:'+account.profileId; return true} else {return false}; // add extra view field
        if (account.url === d.domain) {
            d.analytic = account;
            return true;
        } else {
            return false; 
        }; 
      });
    });

    //setState({data: newdata}); // update the data array with view records   

    //console.log('XXXXXXXXXX', newdata);

    return SortAndIndex(newdata);
};



export {contentFsm, refreshTimeoutMins, minsOld, penceToPounds, EpdSmartApiLoad, EpdFullApiLoad, SortAndIndex, AnalyticsMergeToData, RAGhostingPercent, RAGhostingDisk, RAGuptime, pad, timedateConvert, daysOld, byteToMegabyte, secondstotime };
