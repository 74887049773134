// pages/Home.js
import './App.css';

import React, {useEffect} from "react";

//import Logo from './epdlogo_lg.jpg'

import { Spinner, Container, Row, Col, Nav, Button, Image} from 'react-bootstrap';

import { NavLink, withRouter } from 'react-router-dom';

import Logo from './epdlogo_lg.jpg'

import {UptimeMergeToData, BackupMergeToData, HostingMergeToData, EpdSmartApiLoad, EpdFullApiLoad} from './Library';

const textCenter = {
  textAlign: "center"
};

const textRight = {
  textAlign: "right"
};

const rowStyle = {
  // height: '100vw',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
 // marginBottom: '1rem'
  padding: '1rem'
};


/*
const textCenter = {
  textAlign: "center"
};


const rowStyle = {
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  padding: '1rem'
};
*/

//<Row style={{...rowStyle, ...textCenter}}>

const Home = (props) => {

/*
  useEffect(() => {

      console.log('HOME-----------', props);
      const propscopy = {...props, unloading: true}
      EpdSmartApiLoad(props, true);

  },[props]
  )
*/

  const loading = props.state.loading;

  const textStyle = { 
    textAlign: "center"
  }

    return (
      <Container fluid="lg" className="content_container">
        <Row>
            <Col>
                {loading && <div style={textStyle}><Spinner  animation="border" /></div>}
            </Col>
        </Row>
        <Row style={{...rowStyle, ...textCenter}}>
            <Col>
                <img src={Logo} alt="epD logo" width="250px" />
            </Col>
        </Row>
      </Container>
    )
  };

export default Home;
