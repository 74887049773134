import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';



class Header extends React.Component {

    render() {
        /*
        const rowStyle = {
            height: '10rem',
            backgroundColor: rgba(41, 43, 44, 0.1), //  'rgba(255,0,0,.1)',
            //marginTop: '1rem'
        };
        */
        const rowStyle = {
            height: '4rem',
            backgroundColor: 'rgba(41, 43, 44, 0.1)',
            backgroundColor: 'red',
            //marginBottom: '1rem'
            textalign: 'center'
        };

        const textStyle = {
            textAlign: "center"
        }


        return (
            <Container fluid="lg">
                <Row style={rowStyle}>
                    <Col>
                        <h1 style={textStyle}>easypeasyDigital</h1>
                    </Col>
                </Row>
            </Container>
        )
    }
}


export default Header;
